.footerDiv {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}

.cpcDiv{
    font-size: 80%;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}